// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import Cookies from "js-cookie";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcLxFTDLh42HUf4iEwQtKW_OqGFXvs7_Y",
  authDomain: "goongraveyard.firebaseapp.com",
  projectId: "goongraveyard",
  storageBucket: "goongraveyard.appspot.com",
  messagingSenderId: "1020004257344",
  appId: "1:1020004257344:web:8822656f1436787173d9a5",
  measurementId: "G-S5DJ0RC5WZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

var authToken = "";

const useSignInWithGoogle = () => {
      signInWithPopup(auth, googleProvider)
        .then((result) => {
          const user = result.user;
          getUserAuthToken();
        })
        .catch((error) => {
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.email;
          const credential = error.credential;
        });
};

const getUserAuthToken = () => {
  if (!auth.currentUser) { return "" }
  return auth.currentUser?.getIdToken(true).then((idToken) => {
    console.log("GOT A TOKEN GOOD!!");
    authToken = idToken;
    Cookies.set("authToken", idToken, { expires: 9999 });
    return idToken;
  });
}

export { authToken, auth, analytics, useSignInWithGoogle, getUserAuthToken };