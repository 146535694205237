import React from 'react';

const rarityColors = {
    RARE: 'bg-red-500',
    COMMON: 'bg-blue-300',
    UNCOMMON: 'bg-green-500',
    EPIC: 'bg-purple-500',
    LEGENDARY: 'bg-yellow-500'
  };
  
const Card = ({ title, imageUrl, rarity, isTarget }) => {
  const rarityColor = rarityColors[rarity] || 'bg-gray-300';
  const targetStyles = isTarget ? 'target-card' : '';

  return (
    <div className={`max-w-sm rounded shadow-lg m-4 ${rarityColor} ${targetStyles} transition-transform duration-300`}>
      <img className="w-full" src={imageUrl} alt={`Card of rarity ${rarity.toLowerCase()}`} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-white text-center">{title}</div>
      </div>
    </div>
  );
};

export default Card;