import React from 'react';

const CreatorStartPageGuide = () => {
  return (
    <div className='bg-gray-800 min-w-screen min-h-screen'>
    <main className="container bg-gray-800 min-w-screen min-h-screen mx-auto px-4 md:px-6 py-12 md:py-24 lg:py-32">
      <article className="prose prose-lg max-w-3xl mx-auto ">
        <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
          Become a Creator on Lustino
        </h1>
        <section className="mt-8">
          <h2 className="text-2xl font-bold">Why Choose Lustino?</h2>
          <ul>
                    <br></br>
                    <li><strong>Maximize Your Earnings:</strong> Traditional sales models are predictable. Shake things up with Lustino's lootbox model, which taps into the thrill of lootbox gaming to keep your fans coming back!</li>
                    <br></br>
                    <li><strong>Lower Fees, More Profit:</strong> You deserve more for your content. That’s why we only take a <strong>10% cut</strong> on transactions - half of what some other platforms charge.</li>
                    <br></br>
                    <li><strong>Delay content leaks:</strong> Set conditions like time locks or spend thresholds to keep your content exclusive.</li>
                    <br></br>
                    <li><strong>Innovative Monetization:</strong> Bring the proven monetization model of lootboxes to the art community.</li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold">How to Become a Creator on Lustino</h2>
          <ul>
                    <br></br>
                    <li><strong>Sign Up: </strong>Create your Lustino account to get started.</li>
                    <br></br>
                    <li><strong>Access the Creator Dashboard:</strong> Navigate from your homepage to manage your sales.</li>
                    <br></br>
                    <li><strong>Design Your Lootbox: </strong>Customize the contents and set the price of your lootbox.</li>
                    <br></br>
                    <li><strong>Set Up Payouts: </strong>Enter your payment information to ensure smooth transactions.</li>
                    <br></br>
                    <li><strong>Share Your Work: </strong>Link your lootbox to your social media and website.</li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold"></h2>
          <p>Join Lustino today and transform how you profit from your creativity!</p>
        </section>
      </article>
    </main>
    </div>
  );
};

export default CreatorStartPageGuide;
