import React from 'react';
import { motion } from 'framer-motion';
const rarityColors = {
  RARE: 'bg-red-500',
  COMMON: 'bg-blue-300',
  UNCOMMON: 'bg-green-500',
  EPIC: 'bg-purple-500',
  LEGENDARY: 'bg-yellow-500'
};

const VaultCard = ({ item, onOpenPlayer }) => {
  const rarityColor = rarityColors[item.rarity] || 'bg-gray-300';

  return (
    <motion.div onClick={onOpenPlayer} className={`max-w-sm rounded shadow-lg m-4 ${rarityColor}
                    border-2 border-pink-200 hover:border-pink-300 
                    
                    border-2 border-slate-300 hover:border-slate-400`}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20
      }}

    >
      <img className="w-full " src={item.preview_url} alt={`Card of rarity ${item.rarity.toLowerCase()}`} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-white text-center">{item.name}</div>
      </div>
    </motion.div>
  );
};

export default VaultCard;