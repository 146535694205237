import React, { useContext, useState } from 'react';
import { CardTitle, CardDescription, CardHeader, CardContent, CardFooter, Card } from "../../components/ui/card"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select } from "../../components/ui/select"
import { Button } from "../../components/ui/button"
import { useNavigate } from "react-router-dom"
import Navbar from '../../components/Navbar/Navbar';
import { CoinsIcon } from '../../utils/svg_comps';
import { UserContext } from '../../hooks/UserContext';

export default function CheckoutPage() {
  const { user, loading } = useContext(UserContext);

  const navigate = useNavigate();

  const setupCreditPurchase = () => {
    console.log('Credit purchase setup');
    navigate('/user_home');
  }


  return (
    <div className='bg-gray-900 min-h-screen'>
      <Navbar />
      <div key="1" className="flex flex-col items-center bg-gray-900 p-4 dark">
        <Card className="w-full max-w-lg bg-gray-800 text-gray-200 border-0">
          <CardHeader>
            <CardTitle>Checkout</CardTitle>
            <CardDescription>Enter your payment information.</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="product">Product</Label>
              <div className="flex items-center justify-center bg-gray-700 p-4 rounded-xl">
                <div className="flex justify-between w-full">
                  <div className='text-lg text-gray-200 flex space-x-1 align-center justify-center'>
                    <CoinsIcon />
                    <div>
                      50
                    </div>
                  </div>
                  <div className='text-lg text-gray-200 flex space-x-1 align-center justify-center'>
                    {/* Maybe put an elipses or smth here idk */}
                  </div>
                  <div className='text-lg text-gray-200 flex space-x-1 align-center justify-center'>
                    $50
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="name">Name on Card</Label>
              <Input className="bg-gray-700 text-gray-200" id="name" placeholder="Enter your name" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="number">Card Number</Label>
              <Input
                className="bg-gray-700 text-gray-200"
                id="number"
                placeholder="Enter your card number"
                type="number"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="space-y-2">
                <Label htmlFor="month">Expiration Month</Label>
                <Select >
                  <SelectTrigger aria-label="Month" id="month">
                    <SelectValue placeholder="Month" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-700 text-gray-200">
                    <SelectItem value="1">January</SelectItem>
                    <SelectItem value="2">February</SelectItem>
                    <SelectItem value="3">March</SelectItem>
                    <SelectItem value="4">April</SelectItem>
                    <SelectItem value="5">May</SelectItem>
                    <SelectItem value="6">June</SelectItem>
                    <SelectItem value="7">July</SelectItem>
                    <SelectItem value="8">August</SelectItem>
                    <SelectItem value="9">September</SelectItem>
                    <SelectItem value="10">October</SelectItem>
                    <SelectItem value="11">November</SelectItem>
                    <SelectItem value="12">December</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="year">Expiration Year</Label>
                <Select>
                  <SelectTrigger aria-label="Year" id="year">
                    <SelectValue placeholder="Year" />
                  </SelectTrigger>
                  <SelectContent className="bg-gray-700 text-gray-200">
                    <SelectItem value="2024">2024</SelectItem>
                    <SelectItem value="2025">2025</SelectItem>
                    <SelectItem value="2026">2026</SelectItem>
                    <SelectItem value="2027">2027</SelectItem>
                    <SelectItem value="2028">2028</SelectItem>
                    <SelectItem value="2029">2029</SelectItem>
                    <SelectItem value="2030">2030</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 md:col-span-1">
                <Label htmlFor="cvc">CVV</Label>
                <Input className="bg-gray-700 text-gray-200" id="cvc" placeholder="CVV" />
              </div>
            </div>

            <div id="photos" className="space-y-2 flex flex-wrap justify-between">
                <Label htmlFor="number">We accept</Label>
                <div className="flex justify-between items-center align-center space-x-4 w-full">
                  <img src="https://cu1.uicdn.net/6d0/cc95c0a5b7a416b4ca600d20df135/frontend-applications-common/mastercard.svg" alt="" className="basket-image w-1/5 icon"  />
                  <img src="https://cu1.uicdn.net/b52/33f45d608b984c4264f0279d2e192/frontend-applications-common/visa.svg" alt="" className="basket-image w-1/5 icon"  />
                  <img src="https://cu1.uicdn.net/181/fb1e4251e5873d2381995ac300bba/frontend-applications-common/discover.svg" alt="" className="basket-image w-1/5  icon"  />
                  <img src="https://cu1.uicdn.net/c51/8e48d31a87f423862e2f13f71eadc/frontend-applications-common/american-express.svg" alt="" className="basket-image w-1/5  icon"  />
                </div>
              </div>
          </CardContent>
          <CardFooter>
            <Button onClick={() => { setupCreditPurchase() }} className="ml-auto rounded-xl bg-gray-600 hover:bg-gray-500">Submit Payment</Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

function ShoppingCartIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="8" cy="21" r="1" />
      <circle cx="19" cy="21" r="1" />
      <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
    </svg>
  )
}
