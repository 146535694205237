import React from 'react';
import { LogInForm } from '../../components/LogInForm/LogInForm';

const HomePage = () => {
  return (
    <div>
      <LogInForm></LogInForm>
    </div>
  );
};

export default HomePage;
