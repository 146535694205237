import React, { useState, useRef, useEffect, useContext } from 'react';

import Card from '../../Card';
import CardModalPreview from '../../components/CardModalPreview/CardModalPreview';
import Navbar from '../../components/Navbar/Navbar';

import '../../App.css';

import { getBoxItemInfo, checkForPendingLootboxResults, getLootBoxByName, requestOpenLootbox } from '../../utils/server_stuff';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from '../../hooks/useAuthState';

import { motion } from 'framer-motion';
import { animate } from "framer-motion/dom"
import { CoinsIcon } from '../../utils/svg_comps';
import { UserContext } from '../../hooks/UserContext';

const SpinPage = () => {
  const { user, loading, refreshUser } = useContext(UserContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [currentLootbox, setCurrentLootbox] = useState(null);
  const [targetIndex, setTargetIndex] = useState(-1);
  const [mostRecentLootboxRoll, setMostRecentLootboxRoll] = useState(null); // [lootboxRoll, setLootboxRoll
  const [winningBoxItem, setWinningBoxItem] = useState(null); // [winningBoxItem, setWinningBoxItem]
  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const containerRef = useRef(null);

  React.useEffect(() => {
    const fetchUserData = async () => {
      if (isSpinning) return;

      // Check for pending results
      const pending = await checkForPendingLootboxResults();
      if (pending) {
        // Handle pending lootbox results
        setMostRecentLootboxRoll(pending);
        
        // Get the winning item info
        if (cards && cards.length > 0) {
          setWinningBoxItem(cards[cards.findIndex(item => item.uuid === pending.winner_item_id)]);
        } else {
          const item = await getBoxItemInfo([pending.winner_item_id]).then(items => items[0]);
          setWinningBoxItem(item);
        }

        setModalOpen(true);
      }
    };

    fetchUserData();
  }, [user]);

  const navigate = useNavigate();
  const location = useLocation();

  const finishRespondToLootboxResult = async () => {
    refreshUser();
    setModalOpen(false);
  }

  const getLootboxInfo = async () => {
    setIsFetchLoading(true); // Start loading
    try {
      const res = await getLootBoxByName(location.pathname.split("/")[1]).then(data => data.response);
      return res;
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFetchLoading(false); // Finish loading
    }
  }

  const insertGoodDummyCard = (winner_uuid) => {
    const winnerIndex = cards.findIndex(item => item.uuid === winner_uuid);
    const epicIndex = cards.findIndex(item => item.rarity === "EPIC");
    const legendaryIndex = cards.findIndex(item => item.rarity === "LEGENDARY");
    const dummyIndex = legendaryIndex || epicIndex;
    if (dummyIndex) {
      let newCards = [...cards];
      newCards.splice(winnerIndex, 0, newCards[dummyIndex]);
      setCards(newCards)
    }
  }

  // Add a bunch of buffer cards to the start of the list to make it look like a real roulette
  const resizeCardList = (currCards) => {
    if (currCards.length == 0) return []
    let tempCardsList = [...currCards];
    while (tempCardsList.length < 80) {
      let newTempCard = { ...tempCardsList[Math.floor(Math.random() * tempCardsList.length)] };
      newTempCard.uuid = "";
      tempCardsList.unshift(newTempCard);
    }

    return tempCardsList;
  }

  const generateCards = async () => {
    if (isFetchLoading) {
      return [];
    }

    let lootBoxData = currentLootbox
    const boxItemsIds = lootBoxData.box_items_ids;
    const boxItemsInfo = await getBoxItemInfo(boxItemsIds);
    return boxItemsInfo;
  };

  React.useEffect(() => {
    const fetchLootbox = async () => {
      const newLootBoxInfo = await getLootboxInfo()

      if (!newLootBoxInfo)
        navigate('/notfound');

      setCurrentLootbox(newLootBoxInfo);

      const newCards = await generateCards();
      let newCardsList = resizeCardList(newLootBoxInfo.box_items);
      setCards(newCardsList);
    };

    fetchLootbox();
  }, []);

  /**
   * Smoothly scrolls the container to a specific target element.
   * 
   * @param {Element} container - The container element to scroll.
   * @param {Element} target - The target element to scroll to.
   * @param {number} duration - The duration of the scroll animation in milliseconds.
   */
  function smoothScrollTo(container, target, duration = 500) {
    const targetPosition = target.offsetTop;
    const startPosition = container.scrollTop;
    let distance = targetPosition - startPosition;
    // make the distance account for half of the container height and target height
    distance = distance - container.clientHeight / 2 + target.clientHeight / 2;

    let startTime = null;

    // Easing function: easeInOutCubic
    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t * t + b;
      t -= 2;
      return c / 2 * (t * t * t + 2) + b;
    }

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
      container.scrollTop = run;
      setIsSpinning(true);
      if (timeElapsed < duration) requestAnimationFrame(animation); else afterSpin();
    }

    requestAnimationFrame(animation);
  }


  function afterSpin() {
    setIsSpinning(false);
    setModalOpen(true);
    let viewportEle = document.querySelector('.viewport');
    if (viewportEle) {
      let targetEle = document.querySelector('.cards-container').children[0];
      smoothScrollTo(viewportEle, targetEle, 0);

    }

  }


  // actual scrolling is here so we get updated state
  React.useEffect(() => {
    if (targetIndex !== -1) {
      console.log(`New targetIndex is: ${targetIndex}`);
      // Place the scrolling logic here or in a separate function that gets called here.
      let viewportEle = document.querySelector('.viewport');
      if (viewportEle) {

        let targetEle = document.querySelector('.cards-container').children[targetIndex];

        // set duration of scroll. Industry standard is 2-4 secs but we can add variation for optimial dopamine
        // pick random time between 2-4 secs
        let duration = (Math.random() * 4) + 4;
        duration = duration * 1000;


        console.log(`Scrolling to ${targetIndex} with duration ${duration}`);
        smoothScrollTo(viewportEle, targetEle, duration);

      }
    }
  }, [targetIndex])

  // main entry point to spinning
  function spinToIndex(index) {
    setTargetIndex(index);
  }

  const spinRoulette = async () => {
    // Check if already spinning and prevent restart if so
    if (isSpinning) {
      console.log("Already spinning.");
      return; // Prevent starting a new spin
    }

    const container = containerRef.current;
    // Reset container position to start for restart capability
    container.style.top = "0px";

    let lootBoxRoll = await requestOpenLootbox(currentLootbox.uuid).then((data) => {
      return data;
    }).finally(() => {
      refreshUser();
      setModalOpen(false)
    });
    
    if (lootBoxRoll['error']) {
      alert(lootBoxRoll['error']);
      return;
    }

    // Don't add until resolve issues
    // insertGoodDummyCard(lootBoxRoll.winner_item_id);

    setMostRecentLootboxRoll(lootBoxRoll);
    setWinningBoxItem(cards[cards.findIndex(item => item.uuid === lootBoxRoll.winner_item_id)]);
    
    spinToIndex(cards.findIndex(item => item.uuid === lootBoxRoll.winner_item_id))

    // setIsSpinning(true);
    //spinToIndex(currentLootbox.box_items_ids.indexOf(lootBoxRoll.winner_item_id));
    //spinToIndex(Math.floor(Math.random() * cards.length));
    console.log("Spinning to index:", targetIndex);
  };

  return (
    <div className='bg-gray-800 flex flex-col items-center h-screen'>
      <Navbar />
      <div className="main-container">
        {(modalOpen &&


          <CardModalPreview
            item={winningBoxItem}
            mostRecentLootboxRoll={mostRecentLootboxRoll}
            closeModal={() => setModalOpen(false)}
            opened={false}
            finishRespondToLootboxResult={finishRespondToLootboxResult}
          />

        )}


        {(!modalOpen &&
          <motion.div className="fixed other-main-container items-center justify-center xl:w-1/3"
            initial={{ scale: 1.5 }}
            animate={{ scale: 1 }}
            

          >
            <div className="main-container w-full flex flex-col flex-grow h-screen">


              <div className="title-container ">
                <h1 className="text-4xl text-white font-bold"> {currentLootbox?.description} </h1>
              </div>

              <div className="viewport overflow-y-auto w-full md:w-1/2">
                {cards.length > 0 && (
                  <div ref={containerRef} className={`cards-container`}>
                    {cards.map((card, index) => (
                      <Card
                        key={index}
                        title={card.name}
                        imageUrl={card.preview_url}
                        rarity={card.rarity}
                        isTarget={index === targetIndex} // Set the target index here       
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>

            <button id="spinButton" disabled={isSpinning || !user || user?.balance < currentLootbox?.cost || cards.length == 0} onClick={spinRoulette}
              className=" 
      
              spin-button bg-gradient-to-r from-pink-500 to-yellow-500 enabled:hover:from-pink-600 enabled:hover:to-yellow-600 text-white font-bold py-2 px-4 rounded-full disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200">
              
                <div className='flex space-x-1 align-center justify-center'>
                    Spin
                    <div className="w-2"></div>
                    <CoinsIcon/>
                    <div className='align-center'>
                        {currentLootbox?.cost} 
                    </div>
                </div>
            </button>
            
          </motion.div>
        )}



      </div>

    </div>
  );
};

export default SpinPage;