import React, { useState, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { useAuthState } from '../../hooks/useAuthState';
import { getUserData } from '../../utils/server_stuff';

import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import 'firebase/auth';
import Navbar from '../../components/Navbar/Navbar';
import { CoinsIcon } from '../../utils/svg_comps';
import { UserContext } from '../../hooks/UserContext';

export default function BuyCreditsPage() {
  const { user, loading } = useContext(UserContext);

  const navigate = useNavigate();

  const toCheckoutPage = () => {
    console.log('Credit purchase setup');
    navigate('/checkout');
  }

  return (
    <div className="bg-gray-900 min-h-screen">
      <Navbar />
      <div className="flex flex-col items-center p-2">
      <h1 className="text-white text-4xl lg:text-5xl font-bold p-4 pb-6 text-center">Buy credits</h1>
        <div className="grid gap-6 md:grid-cols-3">
          <div className="rounded-xl flex flex-col items-center border border-gray-200 bg-gray-800 rounded-lg p-4 space-y-4">
            <h3 className="text-xl text-white font-semibold">Starter Pack</h3>
            <div className='text-xl text-gray-200 flex space-x-1 align-center justify-center'>
              <CoinsIcon/>
              <div>
                  10
              </div>
            </div>
            <p className="text-2xl text-white font-bold">$10</p>
            <Button onClick={toCheckoutPage} className="w-full border bg-gray-900 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">
              Select
            </Button>
          </div>
          <div className="rounded-xl  flex flex-col items-center border border-gray-200 bg-gray-800 rounded-lg p-4 space-y-4">
            <h3 className="text-xl text-white font-semibold">Pro Pack</h3>
            <div className='text-xl text-gray-200 flex space-x-1 align-center justify-center'>
              <CoinsIcon/>
              <div>
                  50
              </div>
            </div>
            <p className="text-2xl text-white font-bold">$50</p>
            <Button onClick={toCheckoutPage} className="w-full border bg-gray-900 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">
              Select
            </Button>
          </div>
          <div className="rounded-xl  flex flex-col items-center border border-gray-200 bg-gray-800 rounded-lg p-4 space-y-4">
            <h3 className="text-xl text-white font-semibold">Ultimate Pack</h3>
            <div className='text-xl text-gray-200 flex space-x-1 align-center justify-center'>
              <CoinsIcon/>
              <div>
                  100
              </div>
            </div>
            <p className="text-2xl text-white font-bold">$100</p>
            <Button onClick={toCheckoutPage} className="w-full border bg-gray-900 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

