import React, { useContext, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/auth';
import { getAllLootBoxes, getUserData } from '../../utils/server_stuff';
import { useAuthState } from '../../hooks/useAuthState';
import { UserContext } from '../../hooks/UserContext';

const UserHomePage = () => {
    const { user, loading } = useContext(UserContext);
    const [recentLootBoxes, setRecentLootBoxes] = useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchUser = async () => {
            if(!user) return;

            const lootBoxes = await getRecentlyVisitedLootboxes();
            setRecentLootBoxes(lootBoxes);
        };

        fetchUser();
    }, [user]);

    const getRecentlyVisitedLootboxes = async () => {
        return await getAllLootBoxes().then((lootboxes) => {
            return lootboxes.response;
        });
    }

    const signOut = () => {
        auth.signOut().then(() => { navigate("/") });
    }

    return (
        <div className='min-h-screen bg-gray-800'>
            <Navbar />
            <div className=" text-white h p-8">
                <section className="mb-8">
                    <h2 className="text-xl font-bold mb-4">Recently visited lootboxes</h2>
                    <ul className="grid gap-4">
                        {recentLootBoxes.map((lootbox, index) => (
                            <li 
                            key={index}
                            onClick={() => { navigate("/" + recentLootBoxes[index].name) }} className="mb-2 flex items-center bg-gray-200 text-black p-1 rounded-xl max-w-md">
                                <img
                                    className="w-20 h-20 object-cover rounded-xl mr-4"
                                
                                    src={recentLootBoxes[index].thumbnail_url}
                                    style={{
                                        aspectRatio: "100/100",
                                        objectFit: "cover",
                                    }}
                                
                                />
                                <div>
                                    <p>{recentLootBoxes[index].name}</p>
                                    <p className='text-sm text-gray-500'>{recentLootBoxes[index].description}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </section>
                <section>
                    <h3 className="text-xl font-bold mb-4">Settings</h3>
            
                        <ul>
                            <li onClick={() => { navigate("/creator_dashboard") }} className="mb-2">
                                <a className="block" href="#">
                                    Creator Dashboard
                                </a>
                            </li>
                            <li onClick={() => { signOut() }}>
                                <a className="block" href="#">
                                    Logout
                                </a>
                            </li>
                        </ul>
                    
                </section>
            </div>
        </div>
    )
};

export default UserHomePage;

