import React from 'react';
import './Balance.css'

import {CoinsIcon} from '../../utils/svg_comps'
import { useNavigate } from 'react-router-dom';


const Balance = (props) => {
    const navigate = useNavigate();

    const purchaseCreditsPage = () => {
        navigate('/purchase')
    }

    return (
        <div onClick={purchaseCreditsPage} className="user-balance">
            <a href="#responsive-header"> 
                <div className='flex space-x-1'>
                    {/* <img src="/gold-coin.svg" />  */}
                    <CoinsIcon/>
                    <div className='align-center'>
                        {props.user.balance} 
                    </div>
                </div>
            </a>
        </div>
    );
}

export default Balance;