import './index.css';
import React from 'react';


import ReactDOM from 'react-dom/client'; // Correct import for React 18
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './hooks/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  //document.getElementById('root')
);

// root.render(
//   <QueryClientProvider client={queryClient}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </QueryClientProvider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
