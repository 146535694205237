
import React, { useContext, useEffect, useState } from 'react';
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { DropdownMenuTrigger, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem, DropdownMenuContent, DropdownMenu } from "../../components/ui/dropdown-menu"
import { TableHead, TableRow, TableHeader, TableCell, TableBody, Table } from "../../components/ui/table"
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "../../components/ui/card"
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { UserContext } from '../../hooks/UserContext';
import { createEmptyLootBox, getAllUsersLootBoxes } from '../../utils/server_stuff';

export default function Component() {
    const { user, loading } = useContext(UserContext);
    const [yourLootBoxes, setYourLootBoxes] = useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchUser = async () => {
            if (!user || loading) return;

            const lootBoxes = await getLootboxes();
            setYourLootBoxes(lootBoxes);
        };

        fetchUser();
    }, [user]);

    const getLootboxes = async () => {
        return await getAllUsersLootBoxes().then((lootboxes) => {
            return lootboxes.response;
        });
    }

    const onClickCreateLootbox = async () => {
        createEmptyLootBox().then((response) => {
            navigate("/create_lootbox", { state: { lootbox: response.response } });
        });
    };

    return (

        <div className="flex flex-col min-h-screen bg-gray-900">
            <Navbar />
            <main className="grid grid-cols-1 gap-6 md:grid-cols-2 p-4 md:gap-8 md:p-6">
                <div className='space-y-4'>
                    <div className="flex items-center">
                        <h1 className="font-semibold text-xl md:text-2xl">
                            {yourLootBoxes.length === 0 ? "Create your Lootbox" : "Your Lootboxes"}
                        </h1>
                        <Button 
                        onClick={() => { onClickCreateLootbox() }}
                        className="ml-auto rounded-xl" variant='outline' size="sm">
                            Create Lootbox
                        </Button>
                    </div>
                    <div className="space-y-4">
                        {yourLootBoxes.map((lootbox, index) => (
                            <li
                                key={index}
                                onClick={() => { 
                                    navigate("/create_lootbox", { state: { lootbox: lootbox } })
                                }} 
                                className="mb-2 flex items-center bg-gray-200 text-black p-1 rounded-xl w-full">
                                <img
                                    className="w-20 h-20 object-cover rounded-xl mr-4"
                                    src={lootbox.thumbnail_url}
                                    style={{
                                        aspectRatio: "100/100",
                                        objectFit: "cover",
                                    }}
                                />
                                <div>
                                    <p>{lootbox.name}</p>
                                    <p className='text-sm text-gray-500'>{lootbox.description}</p>
                                </div>
                            </li>
                        ))}
                    </div>
                </div>
                <div className="space-y-4">
                    <Card>
                        <CardHeader className="pb-4">
                            <CardTitle>Balance</CardTitle>
                            <CardDescription>Your current balance from sales.</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center justify-center">
                            <h2 className="text-3xl font-bold">$1,234.56</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-400">USD</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-4">
                            <CardTitle>Sales This Week</CardTitle>
                            <CardDescription>Your total sales for the past week.</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center justify-center">
                            <h2 className="text-3xl font-bold">$567.89</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-400">USD</p>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader className="pb-4">
                            <CardTitle>Sales This Month</CardTitle>
                            <CardDescription>Your total sales for the past month.</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center justify-center">
                            <h2 className="text-3xl font-bold">$2,345.67</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-400">USD</p>
                        </CardContent>
                    </Card>
                    <Card className="lg:col-span-3">
                        <CardHeader className="pb-4">
                            <CardTitle>Sales This Year</CardTitle>
                            <CardDescription>Your total sales for the past year.</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center justify-center">
                            <h2 className="text-3xl font-bold">$12,345.67</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-400">USD</p>
                        </CardContent>
                    </Card>
                    {/* <Card className="lg:col-span-3">
                        <CardHeader className="pb-4">
                            <CardTitle>Unique Fans</CardTitle>
                            <CardDescription>Your unique fans in the past week.</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center justify-center">
                            <h2 className="text-3xl font-bold">234</h2>
                            <p className="text-sm text-gray-500 dark:text-gray-400">Fans</p>
                        </CardContent>
                    </Card> */}
                    <Card className="lg:col-span-3">
                        <CardHeader className="pb-4">
                            <CardTitle>Set Up Payouts</CardTitle>
                            <CardDescription>Enable payouts to receive your earnings.</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="flex justify-center">
                                <Button className="rounded-xl" variant='outline' size="sm">
                                    Set Up Payouts
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </main>
        </div>
    )
}

function ArrowLeftIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m12 19-7-7 7-7" />
            <path d="M19 12H5" />
        </svg>
    )
}


function BarChartIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" x2="12" y1="20" y2="10" />
            <line x1="18" x2="18" y1="20" y2="4" />
            <line x1="6" x2="6" y1="20" y2="16" />
        </svg>
    )
}


function BellIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
            <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
        </svg>
    )
}


function CreditCardIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect width="20" height="14" x="2" y="5" rx="2" />
            <line x1="2" x2="22" y1="10" y2="10" />
        </svg>
    )
}


function Package2Icon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
            <path d="m3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9" />
            <path d="M12 3v6" />
        </svg>
    )
}


function PackageIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m7.5 4.27 9 5.15" />
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
        </svg>
    )
}
