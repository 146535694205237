import { auth } from "./auth";

export const BACKEND_URL = "https://api.lustino.com";

export const createEmptyLootBox = async () => {
  try {
    const token = await auth.currentUser.getIdToken();
    return fetch(BACKEND_URL + '/lootbox/create', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(res => res.json());
  } catch { }
}


export const getAllUsersLootBoxes = async () => {
  try {
    const token = await auth.currentUser.getIdToken();
    return fetch(BACKEND_URL + '/lootbox/all/user', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(res => res.json());
  } catch { }
}


export const getAllLootBoxes = async () => {
  try {
    return fetch(BACKEND_URL + '/lootbox/all').then(res => res.json());
  } catch { }
}

export const getAllDataLootBoxByName = async (name) => {
  const token = await auth.currentUser.getIdToken();
  return fetch(BACKEND_URL + `/lootbox/info/all`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ lootbox_name: name })
  }).then(res => res.json());
}

export const getLootBoxByName = async (name) => {
  return fetch(BACKEND_URL + `/lootbox/info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ lootbox_name: name })
  }).then(res => res.json());
}

export const calculateLootboxProbabilities = async (lootbox_items) => {
  try {
    const token = await auth.currentUser.getIdToken();
    return fetch(BACKEND_URL + `/calculate_probabilities`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lootbox_items: JSON.stringify(lootbox_items) })
    }).then(res => res.json());
  } catch (error) {
    console.error('Error:', error);
  }
}

export const getBoxItemInfo = async (ids) => {
  const fetchPromises = ids.map(id =>
    fetch(BACKEND_URL + `/box/${id}`, {
    }).then(res => res.json())
  );

  const results = await Promise.all(fetchPromises);
  return results;
};

export const checkForPendingLootboxResults = async () => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/lootbox/result/pending', {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error:', error);
  }
}

export const requestOpenLootbox = async (lootbox_id) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/lootbox/open', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: auth.currentUser.uid, lootbox_id: lootbox_id })
    })
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error:', error);
  } finally {

  }
}

export const requestPrepareImageUpload = async (formData) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/prepare_image_upload', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
      },
      body: formData
    })
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error:', error);
  }
}

export const requestPrepareVideoUpload = async (lootbox_uuid: string) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/prepare_video_upload', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "lootbox_uuid": lootbox_uuid
      })
    })
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error:', error);
  }
}


export const respondToLootboxResult = async (rollUuid, newStatus) => {
  if (newStatus != "TRADEIN" && newStatus != "VAULTED") return;
  try {
    const token = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/lootbox/result', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "result_id": rollUuid,
        "selection": newStatus
      })
    })
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error:', error);
  }
}

export const getVaultItemInfo = async (ids) => {
  try {
    const currentToken = await auth.currentUser.getIdToken();
    const fetchPromises = ids.map(id =>
      fetch(BACKEND_URL + `/vault/${id}`, {
        headers: {
          "Authorization": `Bearer ${currentToken}`,
          "Content-Type": "application/json",
        },
      }).then(res => res.json())

    );
    const results = await Promise.all(fetchPromises);
    return results;
  } catch (error) {
    console.error('Error:', error);
  }


};


export const getUserData = async () => {
  try {
    const currentToken = await auth.currentUser.getIdToken();
    const response = await fetch(BACKEND_URL + '/user/login/', {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${currentToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: auth.currentUser.uid }),

    })
    console.log(response);
    const data = await response.json();
    if (data.response.user_id)
      return data.response;
    return undefined;
  } catch (error) {
    console.error('Error:', error);
  }
}