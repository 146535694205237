import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/auth'; // Adjust the import path as necessary
import { getUserData } from '../utils/server_stuff';

export const UserContext = createContext({ user: null, loading: true, refreshUser: () => {}});

export const UserProvider = ({ children }) => {
    const [firebaseUser, setFirebaseUser] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (user) {
                // User is signed in.
                setFirebaseUser(user);
                const fetchUser = async () => {
                    const ud = await getUserData();
                    setUser(ud);
                    setLoading(false);
                };
                
                fetchUser();
            } else {
                // User is signed out.
                setFirebaseUser(null);
                setUser(null);
                setLoading(false);
            }
        });
        return () => unsubscribe(); // Clean up subscription on unmount
    }, []);

    const refreshUser = async () => {
        setLoading(true);
        // Assuming 'currentUser' is refreshed with updated data
        const currentUser = auth.currentUser;
        await currentUser?.reload(); // Force reload of user data
        const ud = await getUserData();
        setUser(ud); // Update user data in state
        setLoading(false);
    };

    return (
        <UserContext.Provider value={{ user, loading, refreshUser }}>
            {children}
        </UserContext.Provider>
    );
};
