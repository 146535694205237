import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Balance from '../Balance/Balance';
import './Navbar.css';
import {VaultIcon} from '../../utils/svg_comps'
import { UserContext } from '../../hooks/UserContext';

const Navbar = (props) => {
  const { user, loading } = useContext(UserContext);
  

useEffect(() => {
  if (!loading && !user) {
  //  window.location.href = '/notfound';
  }
}, [loading]);

  return (
    <nav className="bg-pink-700 p-6 w-full border-b border-black">
      <div className="flex justify-stretch">
      
        <div className="text-left w-1/3 justify-start">
            <Link to={user ? '/vault' : '/login'} className="text-xl flex items-center h-full lg:inline-block lg:mt-0 text-white hover:text-white mr-4 flex">
              
              <div className='flex flex-row space-x-1'>
              <VaultIcon/> 
              <div className='text-white'>Vault</div>
              </div>
              
            </Link>
          </div>
          
        <div className="w-1/3">
          <span className="text-center">
              <div className="text-white font-semibold text-2xl lustino-logo ">
            {(user) && (
            <Link to="/user_home">Lustino</Link>
            )}
            {!user && (
            <Link to="/login">Lustino</Link>
            )}
              </div>
            </span>
        </div>
      
        <div className="w-1/3 flex justify-end">
          <div className="text-xl flex items-center h-full block lg:inline-block lg:mt-0 text-white hover:text-white mr-4 text-right">
              {(user) && (
                <Balance user={user}/>
              )}
            {!user && (
              <Link to="/login" className="text-xl flex items-center h-full lg:inline-block lg:mt-0 text-white hover:text-white mr-4 flex">
                Login</Link>
            )}
              
        
          </div>
        </div>

      </div>
    </nav>

  );
};

export default Navbar;