import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../hooks/useAuthState';
import { getUserData } from '../../utils/server_stuff';

const NotFoundPage = () => {
  return (
    <div className='bg-gray-700 min-h-screen '>
      <Navbar/>
      <div className="flex flex-grow items-center justify-center">
        <div className='flex flex-col items-center m-4'>
          <h1 className='text-white text-4xl lg:text-5xl font-bold p-4 text-center'>Page not found</h1>
          <img className="mt-4 rounded-xl border-2" src="https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/carolinaprimepet.com/wp-content/uploads/2023/09/Why-Does-My-Dog-Look-Sad-Featured.png"/>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
