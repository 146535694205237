import React, { useState, useContext } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { getVaultItemInfo } from '../../utils/server_stuff';
import './vaultPage.css';

import VaultCard from '../../components/VaultCard/VaultCard';
import ContentPlayer from '../../components/ContentPlayer/ContentPlayer';
import { UserContext } from '../../hooks/UserContext';
const VaultPage = () => {
  const { user, loading } = useContext(UserContext);
  const [vaultItems, setVaultItems] = useState([]);

  React.useEffect(() => {
    const fetchVault = async () => {
      if (!user || user.vault_item_ids === null) {
        setVaultItems([]);
        return;
      } else {
      const vaultItems = await getVaultItemInfo(user.vault_item_ids);
      setVaultItems(vaultItems);
      }
      console.log('Vault items:', JSON.stringify(vaultItems))
    };
  
    fetchVault();
  }, [user]);

  /// Video Player
  const [playerOpen, setPlayerOpen] = useState(false);
  const [content, setContent] = useState({});

  const openPlayerWithContent = (contentType, source) => {
    setContent({ type: contentType, source: source });
    setPlayerOpen(true);
  };

  const closePlayer = () => {
    setContent({type: '', source: ''});
    setPlayerOpen(false);
  }
  
  return (
    <div className="bg-gray-800 min-h-screen">
      <Navbar user={user} /> 
      <h1 className="text-white text-4xl lg:text-5xl font-bold p-4 text-center">Your vault</h1>

      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {vaultItems.length === 0 && <div className='text-white text-2xl'>No items in your vault</div>}
          {vaultItems.length !== 0 && vaultItems.map((item, index) => (
                        <VaultCard
                          item={item.response}
                          onOpenPlayer={() => openPlayerWithContent(item.response.content_type, item.response.content_url)}
                        />
                    ))}
            <ContentPlayer isOpen={playerOpen} closePlayer={closePlayer} content={content} />
        </div>
      </div>
    </div>
  );
};

export default VaultPage;
