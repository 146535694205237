// FirebaseUI
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import 'firebase/auth';

import React from 'react';
import { LogInForm } from '../../components/LogInForm/LogInForm';

const LogInPage = () => {
    return (
        <div>
            <LogInForm></LogInForm>
        </div>
    )
}

export default LogInPage;