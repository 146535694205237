import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';

import Navbar from '../../components/Navbar/Navbar';
import { useAuthState } from '../../hooks/useAuthState';
import CustomFileUpload from '../../components/MultiFileUpload/MultiFileUpload';
import { CardTitle, CardHeader, CardContent, Card } from "../../components/ui/card"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"
import { Button } from "../../components/ui/button"
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select } from "../../components/ui/select"
import * as tus from "tus-js-client"
import "../../App"
import { calculateLootboxProbabilities, getAllDataLootBoxByName, getLootBoxByName, requestPrepareImageUpload, requestPrepareVideoUpload } from '../../utils/server_stuff';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../hooks/UserContext';

export default function CreateLootboxPage() {
  const { user, loading } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [chances, setChances] = useState([]);
  const [lootbox, setLootbox] = useState({name: '', description: ''} as any); 

  const navigate = useNavigate();
  const location = useLocation();

  const key1 = location.state?.lootbox || {}; // Default to an empty object if state is undefined

  const handleUpload = (event) => {
    const newFiles = event.target.files ? event.target.files : event.dataTransfer.files;
    setFiles(prevFiles => [...prevFiles, ...newFiles]);

    // Generate thumbnails
    Array.from(newFiles).forEach(file => {
      //@ts-ignore
      if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          //@ts-ignore
          setThumbnails(prevThumbnails => [...prevThumbnails, { src: e.target.result, type: file.type }]);
        };
        //@ts-ignore
        reader.readAsDataURL(file);
      }
    });
  };

  useEffect(() => {
    if (!key1 || !key1.name || !user) {
      return;
    }

    // We are editing a lootbox
    getAllDataLootBoxByName(key1.name).then((response) => {
      setLootbox(response.response);

      setFiles(response.response.box_items.map(item => new File([], item.name, { type: item.type })));
      setThumbnails(response.response.box_items.map(item => ({ src: item.thumbnail, type: item.type })));

    }).catch((error) => {
      console.error('Error fetching lootbox data:', error);
      navigate('/creator_dashboard');
    });

  }, [user]);


  useEffect(() => {
    if (!files.length) return;
    determineEachItemChance();
  }, [files]);

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleUpload(event);
  };

  const removeFile = (index) => {
    let _files = [...files];
    _files.splice(index, 1);
    setFiles(_files);

    let _thumbnails = [...thumbnails];
    _thumbnails.splice(index, 1);
    setThumbnails(_thumbnails);
  }

  const determineEachItemChance = () => {
    // let chances = []
    // let rarityUsages = {}

    // // Count number of files with each rarity
    // files.forEach(file => {
    //   if (rarityUsages[file.rarity]) 
    //     rarityUsages[file.rarity]++
    //   else
    //     rarityUsages[file.rarity] = 1
    // });

    // files.forEach(file => {
    //   let fileRarity = rarityOptions.find(option => option.value === file.rarity)


    //   if (fileRarity) {
    //     let chance = fileRarity.chance * (1/rarityUsages[file.rarity])
    //     chances.push(chance)
    //   }
    //   else {
    //     chances.push(0)
    //   }
    // });

    // setChances(chances);
  }

  //[{"id":"196c0895-c14c-4daf-9da7-6a960b932b8a","bunny_vid_guid":"29e592c8-f639-4554-8845-27e635ee2fd5",//"bunny_vid_sig":"f0851b839b65e7de1926fbf568555565fe9bf89963fb9d36a7a9ff04e2828f64","exp_time":1713140794}]

  const saveLootbox = async () => {
    for (const file of files) {
      if (file.type.startsWith('image/')) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('lootbox_uuid', lootbox.uuid)
        await requestPrepareImageUpload(formData).then((response) => {
          console.log("Response is: " + JSON.stringify(response));
        });
      }
      else if (file.type.startsWith('video/')) {
        await requestPrepareVideoUpload(lootbox.uuid).then((response) => {
          console.log("Response is: " + JSON.stringify(response));
            const upload = new tus.Upload(file,
              {
                endpoint: 'https://video.bunnycdn.com/tusupload',
                retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                metadata: { title: file.name, filetype: file.type },
                headers: {
                  AuthorizationSignature: response.bunny_vid_sig, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                  AuthorizationExpire: response.exp_time, // Expiration time as in the signature,
                  VideoId: response.bunny_vid_guid, // The guid of a previously created video object through the Create Video API call
                  LibraryId: response.library_id,
                },
                onError: function (error) { console.log(`Error uploading ${file.name}: ${error}`); },
                onProgress: function (bytesUploaded, bytesTotal) {
                  const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                  console.log(`Uploaded ${bytesUploaded} of ${bytesTotal} bytes (${percentage}%) for ${file.name}`); const p = document.createElement('p'); p.textContent = `${file.name}: ${percentage}%`;
                  // progressDiv.appendChild(p);
                },
                onSuccess: function () {
                  console.log(`Video ${file.name} uploaded successfully`);
                 
                }
              });
            upload.start();
        })
      }
    }
  }

  const rarityOptions = [
    { label: 'Common', value: 'common', color: '#d3d3d3', chance: 50 },
    { label: 'Uncommon', value: 'uncommon', color: '#1e88e5', chance: 30 },
    { label: 'Rare', value: 'rare', color: '#8e24aa', chance: 15 },
    { label: 'Legendary', value: 'legendary', color: '#fdd835', chance: 5 }
  ];

  return (
    <div key="1" className="min-h-screen min-w-screen bg-gray-900 dark">
      <Navbar />
      <div className="max-w-2xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <Card className="mb-6 dark:bg-gray-800">
          <CardHeader>
            <CardTitle className="dark:text-white">Lootbox Details</CardTitle>
          </CardHeader>
          <CardContent>
            <Label className="dark:text-gray-200" htmlFor="lootbox-name">
              Lootbox Name
            </Label>
            <Input
              required
              className="dark:bg-gray-700 dark:text-white dark:border-gray-600"
              id="lootbox-name"
              placeholder="Enter Lootbox Name"
              value={lootbox.name}
              onChange={
                (e) => {
                  const tempLootbox = { ...lootbox }
                  tempLootbox.name = e.target.value
                  setLootbox(tempLootbox)
                }
              }
            />
            <br></br>
            <Label className="mt-4 dark:text-gray-200" htmlFor="lootbox-description">
              Lootbox Description
            </Label>
            <Textarea
              className="dark:bg-gray-700 dark:text-white dark:border-gray-600"
              id="lootbox-description"
              placeholder="Enter Lootbox Description"
              value={lootbox.description}
              onChange={
                (e) => {
                  const tempLootbox = { ...lootbox }
                  tempLootbox.description = e.target.value
                  setLootbox(tempLootbox)
                }
              }
            />
          </CardContent>
        </Card>
        <Card className="mb-6 dark:bg-gray-800">
          <CardHeader>
            <CardTitle className="dark:text-white">Lootbox Thumbnail</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div onDragOver={handleDragOver} onDrop={handleDrop} className="flex flex-col items-center justify-center gap-2">
              <UploadIcon className="w-12 h-12 text-gray-400 dark:text-gray-500" />
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <span className="hidden sm:inline">Drag 'n' drop a file here, or </span>
                click to select a file
              </p>
              <Input className="hidden" id="thumbnail-upload" type="file"
                onChange={handleUpload}
              />
              <Label className="cursor-pointer" htmlFor="thumbnail-upload">
                <Button className="dark:border-gray-600 dark:text-gray-200" variant="outline"
                  onClick={() => document.getElementById('thumbnail-upload').click()}
                >
                  Select File
                </Button>
              </Label>
            </div>
          </CardContent>
        </Card>
        <Card className="mb-6 dark:bg-gray-800">
          <CardHeader>
            <CardTitle className="dark:text-white">Lootbox Content</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div onDragOver={handleDragOver} onDrop={handleDrop} className="flex flex-col items-center justify-center gap-2">
              <UploadIcon className="w-12 h-12 text-gray-400 dark:text-gray-500" />
              <p className="text-sm text-gray-500 dark:text-gray-400">
                <span className="hidden sm:inline">Drag 'n' drop content here, or </span>
                click to select a file
              </p>
              <Input className="hidden" id="file-upload" multiple type="file"
                onChange={handleUpload}
              />
              <Label className="cursor-pointer" htmlFor="file-upload">
                <Button className="dark:border-gray-600 dark:text-gray-200"
                  onClick={() => document.getElementById('file-upload').click()}
                  variant="outline">
                  Select Files
                </Button>
              </Label>
            </div>

            {Array.from(files).map((file, index) => (
              <div key={index} className="mt-6 space-y-4">
                <div className="border-t pt-4 dark:border-gray-700">
                  <div className="flex items-center gap-4">
                    <img
                      alt="File thumbnail"
                      className="aspect-square object-cover rounded-xl"
                      height="75"
                      src={thumbnails[index]?.src || '/placeholder.png'}
                      width="75"
                    />
                    <p className="font-medium dark:text-white">{file?.name}</p>
                    <div className='w-full'></div>
                    <Button
                      onClick={() => { removeFile(index); }}
                      className="dark:text-gray-200" variant="ghost">
                      <TrashIcon className="w-4 h-4" />
                    </Button>
                  </div>
                  <div className="mt-4 lg:flex lg:gap-4">
                    <div className="lg:flex-1">
                      <Label className="dark:text-gray-200" htmlFor="rarity-1">
                        Rarity
                      </Label>
                      <Select
                        value={file?.rarity || ''}
                        onValueChange={(e) => {
                          let _files = [...files];
                          _files[index].rarity = e;
                          setFiles(_files);
                        }}
                      >
                        <SelectTrigger aria-label="Rarity">
                          <SelectValue placeholder="Set Rarity" />
                        </SelectTrigger>
                        <SelectContent className="bg-gray-800 text-gray-200">
                          <div>
                            {rarityOptions.map((option, index) => {
                              return <SelectItem key={index}
                                value={option.value}>{option.label}</SelectItem>
                            })}
                          </div>
                        </SelectContent>
                      </Select>


                    </div>
                    <div></div>
                    <div className="mt-4 lg:mt-0 lg:flex-1">
                      <Label className="dark:text-gray-200" htmlFor="chance-1">
                        Chance to unbox
                      </Label>
                      <div
                        className="flex h-10 w-full rounded-xl px-0 py-2 text-sm"
                        id="chance-1"
                      >
                        {chances[index]}%
                      </div>
                    </div>
                    <div className='mt-4 lg:mt-0 lg:flex-1'>
                      <Label className="dark:text-gray-200" htmlFor="chance-1">
                        Avg. spent to unbox
                      </Label>
                      <div
                        className="flex h-10 w-full rounded-xl px-0 py-2 text-sm"
                        id="chance-1"
                      >
                        $10
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            ))}

          </CardContent>
        </Card>
        <div className="flex justify-end">
          <Button
            disabled={!files.length || !lootbox.name || files.some(file => file.rarity === undefined)}
            onClick={() => saveLootbox()}
            className="!bg-gray-800 dark:text-white dark:border-gray-700" variant="outline">
            Save Lootbox
          </Button>
        </div>
      </div>
    </div>
  )
}

function TrashIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  )
}


function UploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="17 8 12 3 7 8" />
      <line x1="12" x2="12" y1="3" y2="15" />
    </svg>
  )
}

// === styles.css ===

// :root {
//   --radius: 0.5rem;
// }

// body {
//   font-family: var(--font-dm_sans), sans-serif;
// }

// h1, h2, h3, h4, h5, h6 {
//   font-family: var(--font-dm_sans), sans-serif;
// }

// === layout.jsx ===

// This is the root layout component for your Next.js app.
// Learn more: https://nextjs.org/docs/app/building-your-application/routing/pages-and-layouts#root-layout-required

// import { DM_Sans } from 'next/font/google'
// import './styles.css'

// const dm_sans = DM_Sans({
//   subsets: ['latin'],
//   display: 'swap',
//   variable: '--font-dm_sans',
// })

// export default function Layout({ children }) {
//   return (
//     <html lang="en">
//       <body className={dm_sans.variable + dm_sans.variable}>
//         {children}
//       </body>
//     </html>
//   )
// }