import React, { useState, useContext } from 'react';
import './CardModalPreview.css';
import { motion } from 'framer-motion';
import { CoinsIcon } from '../../utils/svg_comps';
import { respondToLootboxResult } from '../../utils/server_stuff';
import ContentPlayer from '../ContentPlayer/ContentPlayer';
import { UserContext } from '../../hooks/UserContext';

const rarityColors = {
  RARE: 'bg-red-500',
  COMMON: 'bg-blue-300',
  UNCOMMON: 'bg-green-500',
  EPIC: 'bg-purple-500',
  LEGENDARY: 'bg-yellow-500'
};

const CardModalPreview = ({ item, closeModal, opened, mostRecentLootboxRoll, finishRespondToLootboxResult }) => {
  // const { user, loading } = useContext(UserContext);
  
  const rarityColor = rarityColors[item.rarity] || 'bg-gray-300';

  const contentToDisplay = item.content_url || item.preview_url;

  /// Video Player
  const [playerOpen, setPlayerOpen] = useState(false);
  const [content, setContent] = useState({});

  const openPlayerWithContent = (contentType, source) => {
    setContent({ type: contentType, source: source });
    setPlayerOpen(true);
  };

  const closePlayer = () => {
    setContent({ type: '', source: '' });
    setPlayerOpen(false);
    finishRespondToLootboxResult();
  }

  const selfRespondToLootboxResult = (newStatus) => {
    respondToLootboxResult(mostRecentLootboxRoll.uuid, newStatus).then((newItem) => {
      if (newItem && newStatus == "VAULTED") {
        openPlayerWithContent(newItem.content_type, newItem.content_url);
      }
      else {
          finishRespondToLootboxResult();
      }
    })
  }

  // TODO: Finish this function
  const doesUserOwnItem = () => {
    return true;
  }

  return (

    <div className={`card-modal-preview ${rarityColor} `} 
    id="card-modal-thing" data-modal-placement="bottom-center" data-modal-backdrop="static" hidden={opened}>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
      >
        <div className={`animate-breathing-glow content-container border border-black rounded-xl ${rarityColor}`}>
          <div>
            <img id="preview-img" className="rounded-t-xl rounded-b-none border-b border-black" src={contentToDisplay} alt={`Card of rarity ${item.rarity.toLowerCase()}`} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2 text-white text-center">{item.name}</div>
            </div>
          </div>
        </div>
        </motion.div>
        <div className="fixed inset-x-0 bottom-0 p-4 shadow-md w-full">
          <div className="h-full md:w-auto items-center justify-center w-full flex flex-col space-y-2">
            <motion.button transition={{ duration: 0.3 }}  whileTap={{ scale: 0.85 }} className="text-lg bg-blue-600 border border-black rounded-md to-vault-button w-full md:w-1/2 lg:w-1/3" onClick={() => {selfRespondToLootboxResult("VAULTED")}}>Unlock  {item.content_type.toLowerCase()}</motion.button>
            <motion.button transition={{ duration: 0.3 }}  whileTap={{ scale: 0.85 }} className="bg-pink-600 border border-black rounded-md trade-in-button w-full md:w-1/2 lg:w-1/3" onClick={() => {selfRespondToLootboxResult("TRADEIN")}}>
              <div className='text-lg text-gray-200 flex space-x-1 align-center justify-center'>
              <div>{"Trade in - "}</div>
              <CoinsIcon/>
              <div>
                {mostRecentLootboxRoll.trade_in_value}
              </div>
            </div>
            </motion.button>
          </div>
        </div>
        <ContentPlayer isOpen={playerOpen} closePlayer={closePlayer} content={content} />

    </div>



  );
};

export default CardModalPreview;
