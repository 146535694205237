import React from 'react'
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
// React stuff
import { useEffect } from 'react';

// Auth service
import { auth, useSignInWithGoogle } from '../../utils/auth';
import { onAuthStateChanged } from "firebase/auth";
import 'firebase/auth';

export const LogInForm = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Listen for auth state changes
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          navigate('/user_home');
        } else {
          // User is signed out
          // ...
        }
      });
    }, []);

    return (
        <div className="flex">
            <div className="hidden lg:block w-2/3 bg-red-500">
                <div className="min-h-screen bg-gray-800 text-white flex flex-col items-center justify-center">
                    <div className="text-8xl font-bold">
                        Lustino
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-1/3 bg-blue-500">
                <div key="1" className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center">
                    {/* <img src="" className="text-[#00A3FF] h-12 w-12" /> */}
                    <div className="mb-6 lg:hidden">
                        <h1 className="text-4xl font-bold">Lustino</h1>
                    </div>
                    <Button onClick={useSignInWithGoogle} className="bg-pink-700 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">Sign up</Button>
                    <Button onClick={useSignInWithGoogle} className="bg-pink-700 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">Login</Button>
                    <p className="text-xs mb-4 w-64 text-center">
                        {"By joining, you agree to our "}
                        <a className="text-pink-400" href="#">
                            Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a className="text-pink-400" href="#">
                            Privacy Policy
                        </a>
                        , and confirm that you are at least 18 years old.
                    </p>
                    <Button onClick={useSignInWithGoogle} className="bg-pink-700 text-white rounded-xl w-64 mb-4 p-1 m-1 flex items-center justify-center">
                        <ChromeIcon className="h-6 w-6 mr-2" />
                        Sign in with Google
                    </Button>
                    <footer className="text-xs">
                        <p className="mb-4">© 2024 Lustino</p>
                        <div className="mb-4 flex space-x-2">
                            {/* <Link className="block" href="#">
                                <TwitterIcon className="h-5 w-5" />
                            </Link>
                            <Link className="block" href="#">
                                <InstagramIcon className="h-5 w-5" />
                            </Link> */}
                        </div>
                        {/* <div className="mb-4">
                            <GlobeIcon className="h-5 w-5 inline mr-1" />
                            Language
                        </div> */}
                        <div className="mb-8 space-y-1">
                            <a className="block" href="mailto:team@lustino.com">
                                Contact
                            </a>
                            <a className="block" href="terms_of_service">
                                Terms of Service
                            </a>
                            <a className="block" href="privacy_policy">
                                Privacy Policy
                            </a>
                            {/* <a onClick={() => {navigate("#")}} className="block" href="#">
                                USC 2257
                            </a> */}
                            <a className="block" href="creator_faq">
                                Become A Creator
                            </a>
                        </div>
                        <div className="flex justify-between w-64">
                            {/* <SettingsIcon className="h-6 w-6" />
                            <SearchIcon className="h-6 w-6" /> */}
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
}

function ChromeIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <circle cx="12" cy="12" r="4" />
            <line x1="21.17" x2="12" y1="8" y2="8" />
            <line x1="3.95" x2="8.54" y1="6.06" y2="14" />
            <line x1="10.88" x2="15.46" y1="21.94" y2="14" />
        </svg>
    )
}


function FanIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10.827 16.379a6.082 6.082 0 0 1-8.618-7.002l5.412 1.45a6.082 6.082 0 0 1 7.002-8.618l-1.45 5.412a6.082 6.082 0 0 1 8.618 7.002l-5.412-1.45a6.082 6.082 0 0 1-7.002 8.618l1.45-5.412Z" />
            <path d="M12 12v.01" />
        </svg>
    )
}


function GlobeIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="2" x2="22" y1="12" y2="12" />
            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
        </svg>
    )
}


function InstagramIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
            <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
        </svg>
    )
}


function SearchIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}


function SettingsIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
            <circle cx="12" cy="12" r="3" />
        </svg>
    )
}


function TwitchIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7" />
        </svg>
    )
}


function TwitterIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
        </svg>
    )
}
