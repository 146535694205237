import React from 'react';

import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import SpinPage from './pages/SpinPage/SpinPage';
import LogInPage from './pages/LogInPage/LogInPage';
import CreateLootboxPage from './pages/CreateLootboxPage/CreateLootboxPage';
import VaultPage from './pages/VaultPage/VaultPage';
import './App.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import 'primereact/resources/themes/lara-dark-pink/theme.css';


import BuyCreditsPage from './pages/BuyCredits/BuyCreditsPage';
import FaqPage from './pages/FaqPage/FaqPage';
import TermsOfServicePage from './pages/TermsOfServicePage/TermsOfServicePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ContactPage from './pages/ContactPage/ContactPage';
import UserHomePage from './pages/UserHomePage/UserHomePage';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import CreatorFaqPage from './pages/CreatorFaqPage/CreatorFaqPage';
import CreatorDashboardPage from './pages/CreatorDashboardPage/CreatorDashboardPage';

const App = () => {
  return (
    <PrimeReactProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/vault" element={<VaultPage />} />
        <Route path="/user_home" element={<UserHomePage />} />
        <Route path="/create_lootbox" element={<CreateLootboxPage />} />
        <Route path="/creator_dashboard" element={<CreatorDashboardPage />} />
        <Route path="/creator_faq" element={<CreatorFaqPage />} />
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="/purchase" element={<BuyCreditsPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms_of_service" element={<TermsOfServicePage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<SpinPage />} />
      </Routes>
    </PrimeReactProvider>
  );
}
export default App;