import React, { useEffect } from 'react';
import './ContentPlayer.css';

const ContentPlayer = ({ isOpen, closePlayer, content }) => {
  
  // Effect to manage fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && content.type === 'video') {
        // Exited fullscreen, close the dialog
        closePlayer();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [content.type, closePlayer]);

  // Function to request fullscreen on video element when it's loaded
  const handleVideoLoaded = (e) => {
    if (e.target.requestFullscreen) {
      e.target.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }
  };

  // Function to handle when the video ends
  const handleVideoEnded = () => {
    if (content.type === 'video'){
      closePlayer();
    }
  };
  
  return (
    <>
      {isOpen && (
        <dialog className="contentDialog" open onClick={closePlayer}>
          {content.type === 'video' && (
            <video controls autoPlay className="content" onLoadedMetadata={handleVideoLoaded} onEnded={handleVideoEnded}>
              <source src={content.source} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {content.type === 'image' && (
            <img src={content.source} alt="Content" className="content" />
          )}
          {/* <button onClick={closePlayer} className="closeDialog">Close</button> */}
        </dialog>
      )}
    </>
  );
};

export default ContentPlayer;
